import * as React from 'react'
import './addArticle.css'

export const AddArticle = (props: any) =>
  <svg width={props.svgwidth} height={props.svgheight} viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="44" height="44" fill="white"/>
      <path
        d="M34.5 7H9.5C8.11929 7 7 8.11929 7 9.5V34.5C7 35.8807 8.11929 37 9.5 37H34.5C35.8807 37 37 35.8807 37 34.5V9.5C37 8.11929 35.8807 7 34.5 7Z"
        fill="#72CAC2" fill-opacity="0.5" stroke="#1E4E69" stroke-width="2" stroke-linejoin="round"/>
      <path d="M22 15.3333V28.6667V15.3333ZM15.3333 22H28.6666H15.3333Z" fill="#72CAC2"/>
      <path d="M22 15.3333V28.6667M15.3333 22H28.6666" stroke="#1E4E69" stroke-width="2" stroke-linecap="round"
            stroke-linejoin="round"/>
  </svg>
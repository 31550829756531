import { RasaContext } from 'context'
import React, { Component } from 'react'
import './_styles.scss'
import * as Types from './types'

export class HeaderComponent extends Component<Types.HeaderComponentProps, Types.HeaderComponentState> {
  public static contextType = RasaContext

  constructor(props: Types.HeaderComponentProps) {
    super(props)
    this.state = {
      isLoading: true,
    }
  }

  public componentDidMount() {
    this.setState({isLoading: false})
  }

  public render() {
    return (
      <div className="standard-header">
        <div className="titles-and-description-container">
          {
            this.props.title &&
              <h6 className="title">
                {this.props.title}
              </h6>
          }
          <div className="sub-title">
            {this.props.subTitle}
          </div>
          {this.props.description &&
          <div className="description">
            {this.props.description.map((d, i) => <p key={i} dangerouslySetInnerHTML={{__html: d}}></p>)}
          </div>}
        </div>
      </div>
    )
  }
}
